import React, {useContext, useState, useEffect} from "react";
import {userContext} from "../../contexts/user.context"
import {useLazyQuery, useMutation} from "@apollo/client";
import {CREATE_CONTACT_MUTATION} from "../../api/mutations/contact.mutations"
import {UserContext} from "../../contexts/user.context";
import {CONTACT_QUERY} from "../../api/queries/contact.queries";
import {CustomerContext} from "../../contexts/customer.context";
import { useNavigate } from "react-router-dom";

const states = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming']

const CONTACT = {
    email: "", name: "", address1: "", phone:"", city:"", state:"", zip:"", role:"user"
}
const ContactSaveForm = ( {customerId} ) => {
    const [ContactForm, setContactForm] = useState(CONTACT);
    const {email, name, /*password, */address1, phone, city, state, zip, role} = ContactForm;
    const navigate = useNavigate();

    const handleChange = (e) => {
        setContactForm({...ContactForm, [e.target.name]: e.target.value});
    };
    const {user} = useContext(UserContext);


    const [CreateContact] = useMutation(CREATE_CONTACT_MUTATION);


    const handleSubmit = async () => {

        try{

            if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)){
                alert("Your email is incorrect!");
                return false;
            }

            if(!(phone === "" || phone === null)){
                if(!/^[0-9\-\+\s]{10,15}$/.test(phone)){
                    alert("Your phone number is incorrect!");
                    return false;
                }
            }

            if(address1 === ""){
                alert("Address is required!");
                return false;
            }

            if(city === ""){
                alert("City is required!");
                return false;
            }

            if(state === ""){
                alert("State is required!");
                return false;
            }

            if(zip === ""){
                alert("Zip is required!");
                return false;
            }

            if(role === ""){
                alert("Role is required!");
                return false;
            }

            await CreateContact({
                variables: {
                    email: email,
                    name: name,
                    //password: password,
                    address1: address1,
                    phone: phone,
                    city: city,
                    state: state,
                    zip: zip,
                    account: "CODi",
                    customer_id: parseInt(customerId),
                    role: (user?.role === "codi admin" && customerId == user?.customer_id) ? "codi admin": role
                }, onCompleted: (res) => {
                    alert(`Contact created. Contact #${res.createContact.id}`);
                    //setContactForm({});
                    navigate("/manage-contacts/"+customerId);

                },
            });
        }
        catch (error){
            alert(error?.message);
        }

    }

    console.log(customerId);

    return (
        <form className=" gap-4 contact-form">

            <div className="flex flex-col">
                <label className="" htmlFor="name">
                    Name *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="name"
                    id="name"
                    onChange={handleChange}
                    value={name}
                    required
                />
            </div>

            <div className="flex flex-col">
                <label className="" htmlFor="email">
                    Email *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="email"
                    id="email"
                    name="email"
                    onChange={handleChange}
                    value={email}
                    required
                />
            </div>
            {/*<div className="flex flex-col">
                <label className="font-interSemiBold" htmlFor="password">
                    Password:
                </label>
                <input
                    className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="password"
                    id="password"
                    onChange={handleChange}
                    required
                />
            </div>*/}
            <div className="flex flex-col">
                <label className="" htmlFor="address">
                    Address *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="address1"
                    id="address1"
                    onChange={handleChange}
                    value={address1}
                    required
                />
            </div>
            {/*<div className="flex flex-col">
                <label className="font-interSemiBold" htmlFor="address2">
                    Address 2:
                </label>
                <input
                    className="bg-lightGray rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="address2"
                    id="address2"
                    value={address2}
                    onChange={handleChange}
                    required
                />
            </div>*/}
            <div>
            <div className="flex flex-col half-width left" >
                <label className="" htmlFor="city">
                    City *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="city"
                    id="city"
                    onChange={handleChange}
                    required
                    value={city}
                />
            </div>
            <div className="flex flex-col half-width">
                <label className="" htmlFor="state">
                    State *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type='text'
                    name="state"
                    id="state"
                    onChange={handleChange}
                    required
                    value={state}

                />
            </div>
            </div><div>
            <div className="flex flex-col half-width left">
                <label className="" htmlFor="zip">
                    ZIP *
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="zip"
                    id="zip"
                    value={zip}
                    onChange={handleChange}
                    required
                />
            </div>

            <div className="flex flex-col  half-width">
                <label className="" htmlFor="phone">
                    Phone
                </label>
                <input
                    className=" rounded-lg p-2 text-base font-interMedium"
                    type="text"
                    name="phone"
                    id="phone"
                    value={phone}
                    onChange={handleChange}
                    required
                />
            </div>

            </div>

            <div className="flex flex-col">
                <label className="" htmlFor="zip">
                    Role *
                </label>
                { user?.role === "codi admin" && customerId == user?.customer_id ?
                    <div>Codi Admin <input type="hidden" name="role" id="role" onChange={handleChange} value="codi admin" /></div> :
                <select
                    className=" rounded-lg p-2 text-base font-interMedium"
                    name="role"
                    id="role"
                    onChange={handleChange}
                    required
                    value={role}
                >
                    <option value="user">User</option>
                    <option value="admin">Admin</option>
                    <option value="limited">Limited</option>
                </select>
                }

            </div>

            <div className="flex justify-center">
                <button
                        className="flex justify-center text-center font-bold bg-black items-center border-[2px] border-gray-500 px-4 py-2  rounded-2xl"
                        style={{color: "white"}} type={"button"} onClick={handleSubmit}>Save Contact
                </button>
            </div>
        </form>
    );
};

export default ContactSaveForm;
