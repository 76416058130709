import Header from "../components/header.component";
import SubmitClaimNavButton from "../components/buttons/submitClaimNav.button.component";
import emblemImage from "../assets/images/codi-emblem.png";
import {UserContext} from "../contexts/user.context";
import {ContextCustomer, CustomerContext} from "../contexts/customer.context";
import { useNavigate, useLocation } from "react-router-dom";
import {useContext, useEffect} from "react";








const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (path) => {
    if (location.pathname !== `${path}`) navigate(`${path}`);
  };
  const {user} = useContext(UserContext);

     const {customer} = useContext(CustomerContext);

    let navLinks = {"admin": [
            { label: "Dashboard", path: "/dashboard" },
            { label: "Track Claims", path: "/claims" },
            { label: "Assets/Devices", path: "/assets" },
            {
                label: "Shipping Labels",
                path: "/shipping-labels",
            },
            { label: "Account Info", path: "/account-info" },
            { label: "Manage Users", path: "/manage-contacts/" + user?.customer_id},
            { label: "Change Password", path: "/change-password" },
            { label: "Support", path: "/support" },

        ], "user": [
            { label: "Dashboard", path: "/dashboard" },
            { label: "Track Claims", path: "/claims" },
            { label: "Assets/Devices", path: "/assets" },
            {
                label: "Shipping Labels",
                path: "/shipping-labels",
            },
            { label: "Account Info", path: "/account-info" },

            { label: "Change Password", path: "/change-password" },
            { label: "Support", path: "/support" },
        ],"limited": [
          { label: "Dashboard", path: "/dashboard" },
          { label: "Track Claims", path: "/claims" },
          { label: "Assets/Devices", path: "/assets" },
          {
              label: "Shipping Labels",
              path: "/shipping-labels",
          },
          { label: "Account Info", path: "/account-info" },

          { label: "Change Password", path: "/change-password" },
          { label: "Support", path: "/support" },
      ], "codi admin": [
            { label: "Dashboard", path: "/dashboard" },
            { label: "Track Claims", path: "/claims" },
            { label: "Assets/Devices", path: "/assets" },
            {
                label: "Shipping Labels",
                path: "/shipping-labels",
            },
            { label: "Account Info", path: "/account-info" },
            { label: "Manage Codi Admins", path: "/manage-contacts/"+ user?.customer_id },
            { label: "Customers", path: "/customers" },
            { label: "Change Password", path: "/change-password" },
            { label: "Support", path: "/support" },
        ]};

  return (
    <nav className="flex flex-col items-center min-w-[200px] bg-white w-2/6 h-screen py-8 gap-6">
      <img className="codiLogo" src={emblemImage} alt="CODi Emblem" />
      <SubmitClaimNavButton />
      <ul className="flex flex-col font-interExtraBold text-base text-codiDarkGray gap-y-1">
        {navLinks[user?.role ? user?.role : "user"].map(({ label, path }) => (
          <p
            onClick={() => handleClick(path)}
            to=""
            key={label}
            className="font-interExtraBold hover:text-black hover:cursor-pointer"
          >
            {label}
          </p>
        ))}
      </ul>
    </nav>
  );
};

const getCustomerId = (customer) => {
    if(typeof customer !== "object" ) return 0;
    else return customer?.id;
}

export default Navbar;
